:root {
    --primary-color: #106BA3;
    --secondary-color: #182026;
    --tertiary-color: #137CBD;
    --font-family: 'Muli', sans-serif;
    --primary-font-color: #182026;
    --secondary-background-color: #F5F5F5;
    --shadow-color: #0000001A;
    --error-color: #E13737;
    --topic-color: #ACACAC;
    --hover-default-color: #ebf1f5;
    --input-disabled-border-color: rgba(186, 200, 209, 0.5);
    --input-disabled-placeholder-color: rgba(143, 171, 194, 0.6);
    --success-color: #23bc00;
}

html {
    box-sizing: border-box;
    font-size: 12px;
}

body {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 8px;
    transition: .3s ease;
  }
  
::-webkit-scrollbar-track {
  background: var(--shadow-color);
}
  
::-webkit-scrollbar-thumb {
  background: #1c3242;
}
  
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bp3-non-ideal-state {
    height: unset;
    margin-top: 5rem;
}

.sidebar__button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    position: relative;
    background-color: transparent;
    padding: 1.5rem 1.1rem;
    transition: .3s ease;
    outline: none;
    border: none;
    border-top: 1px solid var(--hover-default-color);
    border-bottom: 1px solid var(--hover-default-color);
    color: var(--topic-color);
}

.sidebar__button:not(:last-child) {
    border-bottom: 0;
} 

.sidebar__button.selected {
    color: var(--primary-font-color);
    font-weight: 600;
}

.sidebar__button:hover {
    background-image: none;
    background-color: var(--hover-default-color);
    cursor: pointer;
    text-decoration: none;
}

.sidebar__button--arrow {
    position: absolute;
    right: 7px;
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid var(--primary-font-color);
    border-radius: 1px;
}

.bp3-button.sidebar__button--blue .bp3-icon {
    color: var(--primary-color);
    transition: .3s ease;
}

.bp3-button.sidebar__button--blue:hover .bp3-icon {
    color: white;
}

.bp3-button.sidebar__button--blue {
    border: 1px solid var(--tertiary-color);
    color: var(--tertiary-color);
    font-weight: normal;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1.1rem;
    border: 1px solid var(--tertiary-color);
    border-radius: 3px;
    transition: .3s ease;
    background-image: none;
}

.bp3-button.sidebar__button--blue:not([disabled]):hover {
    background-image: none;
    background-color: var(--primary-color) !important;
    color: white;
}

.bp3-input {
    border: 1px solid lightgray;
    box-shadow: none;
}

.bp3-breadcrumb {
    color: var(--topic-color);
    font-size: 1.1rem;
    font-weight: 600;
}

.bp3-breadcrumbs > li::after {
    opacity: .5;
}

.full__width {
    width: 100%;
}

.bp3-button.button__add--mobile {
    display: none;
  }

@media (max-width: 782px) {
    .sidebar__button {
        display: none;
    }

    .bp3-button.button__add--mobile {
        display: flex;
        color: var(--tertiary-color);
        background-image: none;
        outline: none;
        border: none;
        margin-top: 1rem;
        float: right;
        background-color: transparent;
        box-shadow: none;
    }

    .bp3-button.button__add--mobile .bp3-icon.bp3-icon-add {
        color: var(--tertiary-color);
      }
}
