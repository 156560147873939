.schedule__dashboard {
  position: relative;
  padding: 0 1rem;
}

.schedule__dashboard .schedule__filters {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0rem;
}

.schedule__dashboard .calendar__button {
  background-color: white;
  background-image: none;
  box-shadow: none;
  border: 1px solid var(--grey-color);
  padding: 0.8rem 1.3rem;
  border-radius: 2px;
  color: var(--grey-color);
  transition: 0.3s ease;
}

.schedule__dashboard .calendar__button:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-image: none;
  background-color: white;
  box-shadow: none;
}

.schedule__dashboard .calendar__button.active {
  background-color: var(--primary-color);
  color: white;
}

.schedule__dashboard .calendar__picker__container {
  display: flex;
  align-items: center;
  position: relative;
}

.schedule__dashboard .calendar__picker__container .bp3-icon-calendar {
    position: absolute;
    right: 17rem;
    top: 0.4rem;
}

.schedule__dashboard .calendar__date__picker .calendar {
  position: absolute;
  right: 19rem;
}

.schedule__dashboard .calendar__date__picker .bp3-input-group .bp3-input {
  border: none;
  color: var(--primary-font-color);
  font-weight: 600;
  font-size: 1.4rem;
  background-color: transparent;
  padding-left: 3rem;
}

.schedule__dashboard .calendar__date__picker .bp3-input-group .bp3-input:hover {
    cursor: pointer;
}

.schedule__dashboard .calendar__date__picker .bp3-input-group .bp3-input:focus {
    box-shadow: none;
}

.schedule__dashboard .bp3-icon-chevron-down {
  position: absolute;
  left: 13.9rem;
  z-index: 0;
}

.schedule__dashboard .calendar__actions .bp3-tooltip {
  margin-left: 1rem;
}

.schedule__dashboard .calendar__actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: -2rem;
}

.schedule__dashboard .calendar__actions .bp3-icon {
  padding: 0.5rem;
  margin: 0.6rem;
  border-radius: 4px;
  border: 1px solid transparent;
  transition-timing-function: 0.3s ease;
}

.schedule__dashboard .calendar__actions .bp3-icon:hover {
  border: 1px solid lightgray;
}

.schedule__dashboard .calendar__actions .bp3-icon:focus {
  outline-color: var(--success-color);
}

.schedule__dashboard .calendar__actions .bp3-icon:hover {
  cursor: pointer;
}
